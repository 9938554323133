@use "_variables" as *;
@use "mixins" as *;
@use "utils" as *;
@use "scss/ui3/variables" as *;
@use 'sass:map';

@mixin data-row-size($config) {
  .dx-treelist, .dx-datagrid {
    //ячейки с данными
    .dx-row.dx-data-row, .table-total-row {
      td {
        height: map.get($config, 'height');
        vertical-align: top !important;

        & > span:not(:empty) {
          display: block;
          // Потрібний відступ для літер з йотацією - типу Й, Ї, або діакритичними знаками - типу ä, ё.
          padding-left: 1px;
        }

        .dx-treelist-text-content {
          overflow: visible;
          display: flex;
        }

        .dx-treelist-icon-container .dx-checkbox {
          &.dx-state-focused {
            .dx-checkbox-icon::after {
              transform: scale(0);
            }
          }
        }

        &.dx-treelist-cell-expandable {
          .dx-treelist-text-content {
            max-width: 100%;

            & > span {
              padding-right: 2px;
            }
          }
        }

        &:not(.dx-editor-cell) {
          font-weight: 400;
          @include table-data-cell-size($config);
          // Робимо в звичайній таблиці такий самий відступ як і при інлайн редагуванні для тегбоксів
          &.tags-column {
            padding-left: 4px;
          }

          i, .dx-icon {
            line-height: inherit;
          }

          img {
            width: auto;
            height: 14px;
          }
        }

        .dx-treelist-collapsed {
          top: -4px;
        }

        .right {
          justify-content: flex-end;
        }

        .center {
          justify-content: center;
        }

        .left {
          justify-content: left;
        }

        &.dx-command-drag {
          $padding: map.get($config, 'padding');

          padding-top: $padding - 2;
          padding-bottom: $padding - 1px;
        }
      }
    }

    .table-total-row {
      td {
        &:not(.dx-editor-cell) {
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .dx-row.dx-selection {
      td:not(.dx-focused):not(.d5-selected-cell), .dx-command-drag {
        background-color: $gray;
        color: $dark;
      }
    }

    // Тут регулюємо зняття виділення зі строки якщо налаштований матрас
    .dx-row-alt:not(.marked-node):not(.dx-selection) > td:not(.dx-focused):not(.d5-selected-cell):not(.dx-datagrid-invalid):not(.dx-treelist-invalid) {
      background-color: $gray-6;
    }

    .dx-row.dx-data-row {
      td {
        &.d5-buttons-panel, &.dx-command-drag {
          .dx-datagrid-drag-icon {
            font-size: map.get($config, 'font-size') + 2px;
          }
        }
      }
    }
  }
}

@mixin default-dxdatagrid-styles {
  $hover-text-color: $primary;
  //бг ховеру рядка в таблиці (альтернативний не змінюємо)
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused):not(.dx-row-alt) > td:not(.dx-focused) {
    background-color: transparent;
  }
  //колір тексту під час ховеру
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td {
    color: $hover-text-color;
    //колір іконки переходу до інлайн редагування під час ховеру
    &.d5-buttons-panel {
      .dx-link.edit:before {
        color: $hover-text-color;
      }
    }
  }
}

.table-wrapper {
  .vertical-align-top {
    .dx-data-row > td {
      vertical-align: top !important;
    }
  }

  // размеры ячеек height=30px
  .dx-treelist, .dx-datagrid {
    @include default-dxdatagrid-styles;

    .dx-datagrid-revert-tooltip .dx-revert-button {
      display: none;
    }

    .dx-texteditor-buttons-container {
      .dx-button-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //ячейки с данными
    .dx-data-row {
      td {
        &.d5-buttons-panel {
          padding: 0 !important;
        }
      }

      //картинка в ячейке
      .row-img-block {

        img {
          width: 50px;
          height: auto;
        }
      }

      //ссылка в ячейке
      .row-link-block {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .column-switch-wrapper {
        height: 15px;

        .switch-svg {
          width: 20px;
          height: 100%;
        }

        &.state-readonly {
          .switch-svg-rect {
            opacity: 0.7;
          }

          .switch-svg[data-active=false] {
            .switch-svg-path {
              opacity: 0.8;
            }
          }
        }
      }

      .dx-buttongroup-wrapper {
        justify-content: center;
        height: 20px;

        .dx-button {
          height: 20px;

          .dx-button-content {
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .dx-button-has-text {
          width: 30px;
        }

        .dx-item-selected {
          color: #fff;
          background-color: $gray-2;
        }
      }
    }

    .fixed-columns-summary-wrapper {
      padding-right: 0;
    }
  }
}
