@use "utils" as *;
@use "mixins" as *;
@use "src/scss/ui3/tag-mixins" as *;
@use "src/scss/prepend-slash" as *;
@use "scss/ui3/variables" as * ;
@use 'scss/ui3/tables/variables' as table_vars;
@use '../../../scss/ui3/configs/field' as *;
@use 'scss/modes' as *;
@use 'sass:map';
$field-config: field_config();
$button-config: map.get($field-config, "button");
$focus-cell-border-color: rgba(110, 116, 146, 1);

@mixin focused-editor-border {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 0 1px $focus-cell-border-color;
    pointer-events: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: 0 0 0 1px $focus-cell-border-color;
    pointer-events: none;
  }
}

@mixin inline-borders {
  border: 1px solid $gray;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

@mixin editor-separator {
  content: '';
  position: absolute;
  top: 50%;
  width: .5px;
  height: 20px;
  background-color: $gray;
  transform: translateY(-50%);
}

@mixin inline-editors-size($config) {

  .dx-treelist-content .dx-treelist-table {
    .dx-row-modified {
      border-collapse: inherit;
    }
  }

  .dx-treelist, .dx-datagrid {
    //ячейка во время инлайн редактирования
    td.dx-editor-cell {
      vertical-align: top !important;

      .dx-button-normal {
        &:not(:hover, :focus) {
          background-color: unset;
        }

        &.dx-dropdowneditor-button {
          @include select-dropdown-button-base-styles($button-config);
          border-radius: $field-border-radius;

          &:hover,
          &:focus {
            background-color: $gray;
          }
        }
      }

      & .dx-dropdowneditor-icon {
        @include fa-icon-solid();
        font-size: map.get($config, 'icon-size');

        &:before {
          content: prepend-slash("f078");
          display: block;
          width: 18px;
          top: map.get($config, 'top');
          margin-top: -9px;
          left: 50%;
          margin-left: -9px;
          padding-top: map.get($config, 'dropdown-icon-padding-top');
        }
      }

      //убирает паддинги для чекбоксов в таблице
      &.dx-editor-inline-block:not(.dx-command-select)::before {
        padding: 0;
      }

      &.dx-editor-inline-block {
        & .dx-switch {
          width: 100% !important;
          padding-top: map.get($config, 'switch-padding-top') !important;
          margin-top: map.get($config, 'switch-margin-top') !important;

          .dx-switch-container {
            margin: auto;
          }
        }

        & .dx-switch-wrapper, .dx-switch-inner {
          display: flex;
          align-items: center;

          & .dx-switch-handle {
            width: map.get($config, 'switch-handle-icon-width') !important;
            height: map.get($config, 'switch-handle-icon-heigth') !important;
            flex-basis: map.get($config, 'switch-handle-icon-basis') !important;
          }
        }

        .dx-switch-wrapper {
          padding: 10px;
        }
      }

      .multi-select-column {
        @include tagboxStylingModes(true);
      }

      .dx-texteditor {
        @include table-editing-cell-size($config);

        .dx-texteditor-input-container {
          align-items: center;
          padding: 0;
          margin: 3px 2px;

          &.dx-tag-container {
            @include inline-borders;
            padding: 0 8px;
            align-content: baseline;

            & .dx-texteditor-input {
              height: $field-inline-height !important;
              border: none;
            }
          }
        }

        &.dx-state-disabled {
          background: rgba(255, 255, 255, 0);
          border-radius: 3px;
        }

        &-container {
          display: flex;
          align-items: center;
          min-height: map.get($config, 'inline-height') !important;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;

          .dx-texteditor-input-container, .dx-texteditor-input {
            height: 100%;
            min-height: map.get($config, 'inline-height') !important;
            border: none !important;
            text-align: left !important;
          }

          .dx-texteditor-buttons-container {
            & .dx-clear-button-area {
              margin-right: 4px;
            }
          }
        }
      }

      //на першому діві в комірці з обох боків малюємо рисочки
      & > div:first-of-type {
        position: relative;
        padding-right: 1px;


        &:after {
          @include editor-separator;
          left: unset;
          right: 0;
        }


        &:before {
          @include editor-separator;
          display: block;
          left: 0;
          right: unset;
        }
      }

      .dx-placeholder {
        padding-left: map.get($config, 'padding-left-placeholder');

        &:before {
          font-size: map.get($config, 'font-size');
          padding: map.get($config, 'placeholder-padding') !important;
          height: map.get($config, 'height');
          line-height: map.get($config, 'input-height');
        }
      }

      .dx-selectbox-container {
        .dx-texteditor-input-container {
          margin: 0 !important;
        }
      }

      .dx-texteditor-input-container {
        margin: 0 !important;

        input {
          margin: 0;
        }

        textarea {
          padding: 3px 7px;
          margin: 0;
          min-height: map.get($config, 'input-height');
          height: 100% !important;
          font-size: map.get($config, 'font-size');
          white-space: normal;
          text-align: justify;
          line-height: map.get($config, 'textarea-line-height') !important;
        }
      }

      .dx-checkbox {
        width: 100%;
        margin-top: map.get($config, 'checkbox-margin-top');

        .dx-checkbox-container {
          justify-content: center;
        }

        &.dx-state-focused {
          .dx-checkbox-icon {
            border-color: $focus-cell-border-color;
          }
        }
      }

      .dx-checkbox.dx-state-active .dx-checkbox-icon::after,
      .dx-checkbox.dx-state-focused .dx-checkbox-icon::after {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      & .dx-numberbox {
        & .dx-texteditor-input-container .dx-texteditor-input {
          text-align: right !important;
          padding-right: 2px;
        }
      }
    }

    .dx-column-lines>td.dx-editor-cell {
      & > div:first-of-type {
        &:after, &:before {
          display: none;
        }
      }
    }


    .dx-treelist-rowsview {
      .image-column {
        height: map.get($config, 'image-column-size');
        max-height: map.get($config, 'image-column-size');

        div {
          display: flex;
          align-items: center;
        }

        img {
          $imgHeight: map.get($config, 'image-column-size');
          $controlPadding: map.get($config, 'padding');
          max-height: calc(100% - #{$imgHeight} - #{$controlPadding * 2} - 1px);
        }
      }

      .image-column-alignment {
        div {
          display: block;
        }
      }
    }
  }
}

.table-wrapper {
  .dx-datagrid-focus-overlay {
    &:after {
      background-color: unset;
    }
  }

  .dx-row.dx-edit-row {
    position: relative;
    border: none !important;
    border-radius: 4px;

    &.dx-column-lines {
      > td div:first-child::after {
        content: none !important;
        left: 0;
      }
    }
  }

  .dx-row.dx-edit-row {
    // потрібно коли увімкнено Горизонтальні розділові лінії
    &.dx-row-lines td.dx-focused, td:hover {
      &::before {
        box-shadow: none;
        width: 100%;
        border-top: 1px solid $focus-cell-border-color;
        border-left: 1px solid $focus-cell-border-color;
      }
    }

    td {
      position: relative;
    }

    td.dx-focused, td:hover {
      border-top-color: $focus-cell-border-color !important;
      border-bottom-color: $focus-cell-border-color !important;
      @include focused-editor-border;

      //у виділеній комірці прибираємо розділові палички
      & > div:first-of-type::after {
        display: none !important;
        left: 0;
      }

      & > div:first-of-type::before {
        display: none !important;
        right: 0;
      }

      // в сусідній ліворуч комірці прибираємо ліву паличку щоб вона не налазила на бордер
      & + td > div:first-of-type::before {
        left: -1px;
      }
    }

  }

  .dx-texteditor-buttons-container {
    > div:empty {
      display: none;
    }
  }

  .dx-edit-row {
    input, textarea {
      @include inline-borders;
    }

  }

  .vertical-align-top {
    .d5-buttons-panel {
      & > span {
        align-items: flex-start;
        padding-top: 6px;
      }
    }
  }


  .dx-edit-row {
    .d5-buttons-panel {
      background-color: #fff !important;
    }

    &.dx-row-alt {
      .d5-buttons-panel {
        background-color: #f3f5fa !important;
      }
    }
  }

  td.dx-focused.d5-buttons-panel {
    a.edit {
      color: #34aff9 !important;
    }
  }

  .d5-buttons-panel {
    border-left: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
    vertical-align: middle;

    //кнопки в инлайн редактировании
    a {
      position: relative;
      top: 6px;
      font-size: 1.20rem !important;
      margin-right: 6px;
      text-decoration: none;
      height: auto !important;
      width: auto !important;
      padding-top: 6px;
      cursor: pointer;
      background-color: transparent !important;

      &:focus {
        background-color: transparent !important;
        outline: solid 2px $primary-30 !important;
      }


      &.edit {
        font-size: 1.6em !important;

        &:focus {
          &:before {
            color: $secondary !important;
          }

          &:after {
            color: #fff !important;
          }
        }

        &:before {
          color: $gray-2;
        }

        &:hover {
          &:before {
            font-size: inherit !important;
            color: $primary;
          }

          &:after {
            font-size: inherit !important;
            color: $secondary
          }
        }
      }

      &.save {
        color: $success !important;
      }

      &.cancel {
        color: $gray-2 !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .boolean-editor-wrapper {
    outline: none;

    &:focus {
      .boolean-selector {
        outline: none;
      }

      .dx-buttongroup-wrapper {
        outline: 2px solid $gray-2;
        border-radius: 5px;
      }

      .dx-state-readonly {
        .dx-checkbox-icon {
          border-color: $gray-2 !important;
        }
      }

      .readonly-state {
        outline: 2px dotted $gray-2;
      }
    }

    .boolean-selector {
      margin-top: 4px;
      justify-content: center;
      height: 20px;

      .d5-field {
        min-width: 48px;
        width: auto;
      }

      .dx-button {
        height: 20px !important;

        .dx-button-content {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .dx-button-has-text {
        width: 48px !important;
        min-width: 48px !important;
      }
    }
  }

  .table-boolean-wrapper {
    &:focus {
      .readonly-state {
        outline: none;

        .buttons-group {
          outline: 2px dotted $gray-2;
          border-radius: 5px;
        }
      }
    }
  }
}

.dx-tag + .dx-texteditor-input {
  border: none !important;
}

.dx-editor-cell.dx-focused .dx-texteditor-input {
  box-shadow: none !important;
}

.dx-tagbox-popup-wrapper {
  & .dx-list-select-all {
    display: none;
  }
}

@function get_paddingTop($size) {
  $small-config: ('padding-top': 2px);
  $medium-config: ('padding-top': 6px);
  $large-config: ('padding-top': 10px);

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@function get_texteditorSizes($size) {
  $small-config: ('padding': 2px 7px);
  $medium-config: ('padding': 7px 8px);
  $large-config: ('padding': 9px 7px);

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@each $size in table_vars.$row-sizes {
  #root.root-#{$size} ~ .table-wrapper, #root.root-#{$size} .table-wrapper {

    $config: get_paddingTop($size);

    $config: get_texteditorSizes($size);

    input, textarea.dx-texteditor-input {
      padding: map.get($config, 'padding') !important;
    }
  }
}
