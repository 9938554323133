@use "variables" as *;

$topbar-item-color: $white;

.main-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  flex: 1;

  &-form-title-container {
    margin-right: auto;
    margin-left: 20px;
    display: flex;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;

    @media (max-width: 767px) {
      margin-left: 8px;
    }
  }

  &-form-title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }

  &-form-modifier-text {
    flex-shrink: 0;
    overflow: hidden;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-left: 10px;
  }
}

.topbar-item {
  color: $topbar-item-color;
  width: 28px;
  height: 28px;
  margin-right: 4px;

  @media (max-width: 767px) {
    margin-right: 16px;
  }

  .dx-item {
    border-radius: 50% !important;
    height: 100%;
  }

  .dx-button-text {
    color: $white;
  }

  .dx-button-has-text, .dx-button-content {
    overflow: visible;
    padding: 0;
  }

  .dx-icon, .fa-layers {
    color: $topbar-item-color;
    font-weight: 300;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  .fa-layers {
    svg, path {
      color: $topbar-item-color;
    }
  }
}

.profile-button-dropdown {
  div[title] {
    .dx-list-item-icon-container {
      height: unset;
    }
    &.dx-state-disabled {
      .dx-list-item-content, .dx-list-item-icon {
        color: $gray;
      }
    }
    &.dx-list-item-selected  {
      background-color: transparent !important;

      &.dx-state-hover {
        background-color: $hovered-select-item !important;
        color: $secondary !important;
      }
    }
  }
}

.button-list-container {
  .dx-list-items {
    .dx-list-group {
      border: none;

      &:first-child .dx-list-group-header {
        padding: 16px 12px 0 12px;
      }
    }

    .dx-list-group-header {
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: $gray-3;
      padding: 12px 12px 0 12px;
      pointer-events: none;
    }

    .dx-list-item {
      .dx-list-item-content {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-1;
        padding: 8px 12px;
      }
    }
  }
}

@media (max-width: 960px) {
  .qr-control__header {
    padding: 10px;

    i {
      margin-right: 10px;
    }
  }
}
