@use "scss/ui3/mixins" as *;
@use "scss/variables" as old_vars;
@use "scss/ui3/variables" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html, body, #app {
  height: 100%;
  overflow: hidden;
}

.dx-button {
  &.dx-button-has-text.dx-button-mode-text .dx-button-content {
    padding: 5px 16px;
  }

  &[data-enable="true"] {
    .icon {
      color: #367FAC;
    }
  }
}

.dx-dropdownbutton {
  &:not(.dx-dropdownbutton-has-arrow) {
    .dx-button.dx-button-has-text .dx-button-content {
      padding-left: 8px;
      padding-right: 8px;
    }

    .dx-button-has-icon:not(.dx-button-has-text) .dx-button-content {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}


.dx-timeview {
  .dx-timeview-field {
    .dx-numberbox-spin-container {
      margin-right: 0 !important;
    }
  }
}

.dx-scrollable-scroll-content, .simplebar-scrollbar  {
  border-radius: 50px;
  background-color: old_vars.$scrollbar-track-bg;
}

.dx-context-menu .dx-menu-separator {
  margin: 0 !important;
}

.dx-context-menu .dx-menu-items-container {
  max-height: 466px;
}

.custom-dropdown-menu {

  .custom-dropdown-caret {
    margin-left: 5px;
  }

  .dx-menu-item .dx-menu-item-content .dx-icon {
    margin-right: 3px;
  }
}

/* END buttonsForm style */

/* Form style */

.dx-datagrid-content {
  .dx-state-disabled.dx-widget {
    opacity: .8;
  }

  .dx-texteditor.dx-editor-outlined.dx-state-disabled {
    box-shadow: unset;
  }

  // Нижче стилі для box-shadow для выдкритого на inline редагування рядку.
  // Якщо задавати просто на tr, то через те , що DX рендерить декілька таблиць одночасно відбувається
  // некоректне накладання тіней .
  .dx-datagrid-table-fixed.dx-pointer-events-none {
    @include inline-editing-row-borders-styles;
  }
}

.dx-treelist-content {
  .dx-treelist-table-fixed.dx-pointer-events-none {
    @include inline-editing-row-borders-styles;
  }
}
/* Для macOS прибираємо розраховану DX висоту скролу під таблицею з фіксованими колонками,
 тому що нативний скрол прозорий і через нього видно контент таблиці */
.macos-table {
  .dx-datagrid-content.dx-datagrid-content-fixed[aria-owns],
  .dx-treelist-content.dx-treelist-content-fixed[aria-owns] {
    margin-bottom: 0 !important;
  }
  /* Цей padding вирішував проблему з тим що останній рядок обрізався в таблиці
  але ця проблема відсутня на macOS*/
  .dx-scrollable-content {
    padding-bottom: 0 !important;
  }
}

.form {
  .dx-item-content {
    max-width: 100%;

    .detail-form-cont {
      height: 600px;
    }

    > .dx-item.dx-box-item {
      max-width: 50%;
    }
  }

  .dx-tabpanel-container {
    .dx-item-content {

      .detail-form-cont {
        max-height: 400px;
      }
    }
  }
}

.dx-button-text {
  text-transform: none;
}

.dx-texteditor-buttons-container {
  div {
    &:empty {
      display: contents;
      margin-right: 7px;
    }
  }
}

//всі кнопки в додатку тільки квадраті
.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 4px !important;
}

.dx-show-clear-button .dx-icon-clear {
  color: $field-text-color;
  height: 14px;
  width: 14px;
  font-size: 6px !important;
  font-weight: bold;
  margin-top: 0;
  left: 7px;
  transform: translate(-50%, -50%);
  background-color: $gray;

  &:hover {
    background-color: $gray;
  }

  &:before {
    content: url('../../public/icons/close.svg');
    margin-top: 0;
    margin-left: 0;
    height: 6px;
    width: 6px;
    line-height: 7px;
    transform: translate(-50%, -50%);
  }
}

.dx-popup-wrapper {
  .form {
    .dx-item-content {
      > .dx-item.dx-box-item {
        max-width: 100%;
      }
    }
  }
}

/* #59503
ужимается сильно в модальном окне фильтрации, на операции isanyof - закоментировал
.dx-tagbox-popup-wrapper {
  .dx-overlay-content {
    width: auto !important;
  }
}
*/

.bold {
  font-weight: bold;
}

.error-text {
  color: $error;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.dxx .dx-popup-content {
  padding: 0;
}

.dx-treeview-node a {
  color: inherit;
  height: 100%;
  width: 100%;
  display: block;
}

.d5-react-svg-icon {
  & > div {
    position: relative;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

[role='checkbox'][aria-checked='false'] {
  .dx-checkbox-icon {
    border-color: $field-border-color;
  }
}

.logo-svg-path {
  fill: url(#logo-svg-gradient);
}

.logo-svg-stop1 {
  stop-color: var(--logo-svg-stop1-color, #fff);
}

.logo-svg-stop2 {
  stop-color: var(--logo-svg-stop2-color, #fff);
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-auto {
  flex-basis: auto;
}
