//primary - blue

$background: #E5E5E5; //RGBA(229,229,229,1)

$primary: #1453F8; // rgba(20, 83, 248, 1);
$primary-15: #D9E2FB;
$primary-30: #B3C5F6;
$primary-60: #668CED;
$primary-110: #003FE1;

$secondary: #34AFF9; //light blue rgba(52, 175, 249, 1)
$secondary-transparent: rgba(52, 175, 249, 0.1); //light blue rgba(52, 175, 249, 0.1)
$secondary-medium-transparent: rgba(52, 175, 249, 0.25); //light blue rgba(52, 175, 249, 0.25)
$secondary-2: #D9F3FB;
$secondary-purple: #9665C5; // rgba(150, 101, 197, 1)

$saved-filter: #AE99DB;
$saved-filter-by-default: #202A3C;
$saved-filter-by-default-mobile: #8125D8;

$dark: #202A3C; //rgba(32, 42, 60, 1)
$dark-15: #ECEDEE;
$dark-30: #BCBFC5; //rgba(188, 191, 197, 1)
$black-tints-900: #1A1A1A;
$black: #000000;

$gray: #D4D7E4;
$gray-1: #36445E; //rgba(54, 68, 94, 1)
$gray-2: #6E7492; //rgba(110, 116, 146, 1)
$gray-3: #BFC2CF;
$gray-readOnly: #f6f6f6;
$light-gray-readOnly: #e5e5e5;
$dark-gray-readOnly: #B8B8C0FF;
//бордеры в выделенных строках строках
$gray-5: #DEF3FF; //rgba(222, 243, 255, 1)
$gray-5-alpha: #BFC7D6;
//Row alternation background color
$gray-6: #F3F5FA; //rgba(243, 245, 250, 1)
$gray-7: #C3CED7;
$gray-7-alpha: #C3CED77F;

$default-dx-hover: rgba(6, 65, 218, 0.08);
$hovered-select-item: rgba(0, 0, 0, 0.04);

$error: #F7685B; //rgba(247, 104, 91, 1)
$success: #00D058; //rgba(0, 208, 88, 1)
$base-success: #8bc34a;
$warn: #FFA800;

$white: #fff;

$yellow: #F2C94C;

$primary-dark: #797F8A;

$base-editor-height: 30px;

$notification-shadow: 0px 4px 16px 0px #00000040;

$focused-color: #4375f9;

$borderPanelColor: #89A9FB33;
$lightBorderPanelColor: #e0e0e0;

$selectedCardItemColor: #56CCF2;

$toolbarItemBorderBottom: hsla(0, 0%, 40%, .08);
$favorite-filter: #F2C94C;
$favorite-filter-mobile: $yellow;

//THEME--------------------------------------

$table-toolbar-height: 24px;

$toolbar-icon-size: 18px;
$toolbar-FA-icon-size: 18px;

$toolbar-btn-padding: 2px;

$context-menu-item-height: 32px;
$global-icon-size: 14px;
$popup-design-padding: 24px; //24-на макеті

$border-top-box-shadow: 0 0 16px 0 #00000040;

//FIELD
$field-control-min-width: 110px;
$field-control-height: 32px;
$field-inline-height: 28px;
$field-font-size: 14px;
$field-label-font-size: 13px;
$field-icon-size : 16px;

$field-border-width: 1px;
$field-border-radius: 4px;
$field-border-color: $gray-3;

$field-text-color: $gray-1;
$field-readonly-text-color: $gray-2;
$field-disabled-text-color: $gray-2;

$field-error-color: $error;

$field-focused-color: $primary;

$field-readonly-bg: $gray-6;

//layout item
$layout-item-side-margin: 5px;

$text-button-background: $gray;

