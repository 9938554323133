@use "variables" as *;

@mixin horizontal-resizer-icon($position) {
  content: '';
  background-image: url("../../../public/icons/white-chevron-right.svg");
  background-repeat: no-repeat;
  width: 7px;
  height: 11px;
  position: absolute;
  top: calc(50% - 5px);
  left: 2px;
  @if $position == 'left' {
    transform: rotate(180deg);
  } @else if $position == 'right' {
    left: 1px;
    transform: rotate(0deg);
  } @else if $position == 'top' {
    transform: rotate(-90deg);
  } @else if $position == 'bottom' {
    transform: rotate(90deg);
  }
  cursor: pointer;
}

@mixin vertical-resizer-icon($position) {
  content: '';
  background-image: url("../../../public/icons/white-chevron-down.svg");
  background-repeat: no-repeat;
  width: 11px;
  height: 9px;
  position: absolute;
  left: calc(50% - 4px);
  top: 0;
  @if $position == 'left' {
    top: -1px;
    height: 11px;
    transform: rotate(180deg);
  } @else if $position == 'right' {
    transform: rotate(0deg);
  } @else if $position == 'top' {
    transform: rotate(180deg);
  } @else if $position == 'bottom' {
    top: -1px;
    height: 11px;
    transform: rotate(0deg);
  }
  cursor: pointer;
}

@mixin unset-splitter-group-padding($padding-direction, $value) {
  .group-item {
    .group-container:not(.outlined) {
      padding-#{$padding-direction}: $value !important;
    }
  }
}

.group-splitter-resizer {
  background: transparent;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  position: relative;

  font-weight: 500;
  font-size: 11px;
  color: white;

  &.hidden {
    display: none;
  }
}

.group-splitter-resizer:hover {
  font-size: 15px;
}

.group-splitter-resizer.horizontal {
  height: 11px;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;

  .button-resizer {
    &:before {
      content: '';
      position: absolute;
      height: 4px;
      width: 120px;
      background: $gray;
      left: calc(50% - 60px);
      top: 3px;
      cursor: pointer;
    }

    &:after {
      display:none;
      @include vertical-resizer-icon('left');
    }

  }


  &.folded-vertically {
    .button-resizer {
      &:after {
        @include vertical-resizer-icon('left');
        left:calc(50% - 4px);
        rotate: 180deg;
      }

    }
  }
}

.group-splitter-resizer.horizontal {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;

  &:hover {
    .button-resizer {
      &:after {
        display: block;
      }
      &:before {
        display: block;
        height: 10px;
        top: 0;
      }
    }
  }


  &.folded-vertically {
    .button-resizer:hover {
      &:after {
        left: calc(50% - 8px);
      }
    }

    .chevron-resizer-bottom.button-resizer {
      &:after {
        left: calc(50% - 8px);
      }
    }
    .chevron-resizer-top.button-resizer {
      &:after {
        left: calc(50% - 4px);
      }
    }
  }

  .chevron-resizer-bottom.button-resizer {
    &:after {
      @include vertical-resizer-icon('bottom');
    }
  }

}

.group-splitter-resizer.vertical {
  width: 11px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;

  .button-resizer {
    position: absolute;
    width: 4px;
    height: 120px;
    background: $gray;
    top: calc(50% - 60px);
    right: 50%;
    transform: translateX(50%);
    cursor: pointer;

    &:after {
      @include horizontal-resizer-icon('');
    }
  }
  .chevron-resizer-left.button-resizer {
    &:after {
      @include horizontal-resizer-icon('left');
    }
  }
  .chevron-resizer-right.button-resizer {
    &:after {
      @include horizontal-resizer-icon('right');
    }
  }
  .chevron-resizer-top.button-resizer {
    &:after {
      @include horizontal-resizer-icon('top');
    }
  }
  .chevron-resizer-bottom.button-resizer {
    &:after {
      @include horizontal-resizer-icon('bottom');
    }
  }
  &.folded-horizontally {
    .button-resizer:after {
      rotate: 180deg;
    }
  }
}

.group-splitter-resizer.vertical {
  margin-right: 10px;
  margin-left: 10px;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;

  .button-resizer {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }

  &:hover {
    .button-resizer {
      width: 10px;
      &:after {
        display: block;
      }
      &:before {
        display: block;
      }
    }
  }
}


.group-splitter-resizer.disabled {
  cursor: not-allowed;
}

.group-splitter-resizer.disabled:hover {
  border-color: transparent;
}

.pane-first-vertical {
  @include unset-splitter-group-padding(right, 0);
}

.pane-second-vertical {
  @include unset-splitter-group-padding(left, 0);
}

.pane-first-horizontal {
  @include unset-splitter-group-padding(bottom, 0);
}

.pane-second-horizontal {
  @include unset-splitter-group-padding(top, 0);
}
